import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, Params } from '@angular/router';
import { Hoja } from './models/hoja';
import { HojaService } from './services/hoja.service';
import { NgForm } from '@angular/forms';
import { NotificationService } from './notification.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [HojaService]
})



export class AppComponent implements OnInit {

  public validate = false;
  public title: string;
  public hoja: Hoja;
  public status: string;
  public typeDocuments: Array<any>;
  public typeEstadoCivil: Array<any>;
  public typeSex: Array<any>;
  public typePais: Array<any>;
  public typeCity: Array<any>;
  @ViewChild('registerForm') Form: NgForm;


  constructor(
    private _hojaService: HojaService,
    private notify: NotificationService
  ) {
    this.title = "Registro"
    this.showTypeDocuments();
    this.showTypeEstadoCivil();
    this.showTypeSex();
    this.showTypePais();
    this.showTypeCity();
   
       this.hoja = new Hoja(0,0,'','','',0,0,'','',0,'',0,'','',0,0,'');

  }

  ngOnInit() {
    console.log(' Componente de registro hoja de vida  ! cargado !')
  };

  private showTypeDocuments() {
    this._hojaService.typeDocuments().subscribe(data => {
      if (data['TiposId']['length'] > 0) {
        this.typeDocuments = data['TiposId'];
      }
    });
  }
  private showTypeEstadoCivil() {
    this._hojaService.typeEstadoCivil().subscribe(data => {
      if (data["estadocivil"]["length"] > 0) {
        this.typeEstadoCivil = data["estadocivil"];
      }
    });
  }

  private showTypeSex() {

    this._hojaService.typeSex().subscribe(data => {
      if (data["sexo"]["length"] > 0) {
        this.typeSex = data["sexo"];
      }
    });
  }

  private showTypePais() {

    this._hojaService.typePais().subscribe(data => {
      if (data["pais"]["length"] > 0) {
        this.typePais = data["pais"];
      }
    });
  }

  private showTypeCity() {

    this._hojaService.typeCity().subscribe(data => {
      if (data["departamento"]["length"] > 0) {
        this.typeCity = data["departamento"];
      }
    });
  }

  private cleardata() {
    this.hoja.tipo = 0;
    this.hoja.numberid = 0,
      this.hoja.lastname = '',
      this.hoja.firstname = '',
      this.hoja.birth = '',
      this.hoja.civilstatus = 0,
      this.hoja.gender = 0,
      this.hoja.country = '',
      this.hoja.city = '',
      this.hoja.boos = 0,
      this.hoja.mail = '',
      this.hoja.idphone = 0,
      this.hoja.numberphone = '',
      this.hoja.address = '',
      this.hoja.oktyc = null,
      this.hoja.okmark = null,
      this.hoja.barrio=''
  }

  onSubmit() {
    //  console.log(this.hoja);
    // console.log(this._hojaService.pruebas);
    

    if (this.validate && this.hoja.oktyc == 1) {

      if (this.Form.valid) {

        this._hojaService.register(this.hoja).subscribe(
          response => {

            if (response.status == 'succes') {
                              
              this.hoja = new Hoja(0,0,'','','',0,0,'','',0,'',0,'','',null,null,'');
              console.log("Se guardo");
              this.notify.showSuccess("Tu informacion se guardo exitosamente", "Datos Guardados con Exito");
              this.cleardata();
              this.validate = false;
             // document.location.href = 'https://www.helpinghome.com.co/es/';
            } else {
              this.status = "Error";
              this.notify.showError("Este registro ya existe, Verifique Documento y Correo", "Upsss Error");
            }

          },
          error => {
            console.log(<any>error)
          }
        )

      } else {

        this.notify.showWarning("Debe completar los datos requeridos","Upps Informacion incompleta");
        console.log("campos requeridos");

      }


    } else {
      this.validate = true;
      this.notify.showWarning("Asegurese de aceptar los terminos y condiciones, tambien los campos requeridos","Upps Informacion incompleta");
      console.log("campos requeridos");
    }

  }
}
