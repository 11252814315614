export class Hoja{
    constructor(
        public tipo:number,
        public numberid:number,
        public lastname:string,
        public firstname:string,
        public birth:string,
        public civilstatus:number,
        public gender:number,
        public country:string,
        public city:string,
        public boos:number,
        public mail:string,
        public idphone:number,
        public numberphone:string,
        public address:string,
        public oktyc:number,
        public okmark:number,
        public barrio:string
    ){

    }
}