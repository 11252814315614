import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GLOBAL } from './global';
import { Observable } from 'rxjs'
import { Hoja } from '../models/Hoja';

@Injectable()
export class HojaService {
    public url: string;

    constructor(
        public _http: HttpClient
    ) {
        this.url = GLOBAL.url;
    }


    register(hoja): Observable<any> {

        let json = JSON.stringify(hoja);
        let params = 'json=' + json;

        let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

        return this._http.post(this.url + 'register', params, { headers: headers });
    }


    typeDocuments() {

        return this._http.get(this.url + 'tipoid');

    }

    typeEstadoCivil() {
        return this._http.get(this.url + 'estadocivil');
    }

    typeSex() {
        return this._http.get(this.url + 'sexo');
    }

    typePais() {
        return this._http.get(this.url + 'pais');
    }

    typeCity() {
        return this._http.get(this.url + 'departamento');
    }

}
